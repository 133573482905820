import * as R from "ramda";
import { schema } from "../../data";
import { DATA_TYPES } from "../../types";
import { Relation } from "./relation";
function _arrayWithHoles(arr) {
    if (Array.isArray(arr)) return arr;
}
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _instanceof(left, right) {
    if (right != null && typeof Symbol !== "undefined" && right[Symbol.hasInstance]) {
        return right[Symbol.hasInstance](left);
    } else {
        return left instanceof right;
    }
}
function _iterableToArrayLimit(arr, i) {
    var _arr = [];
    var _n = true;
    var _d = false;
    var _e = undefined;
    try {
        for(var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _nonIterableRest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance");
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {
        };
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function _slicedToArray(arr, i) {
    return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}
var getMatchingObject = function(relation) {
    if (Object.values(DATA_TYPES).includes(relation.__typename)) {
        var data = schema[relation.__typename];
        if (Array.isArray(data)) {
            return data.find(function(param) {
                var id = param.id;
                return id === relation.id;
            });
        }
    }
};
var deepCloneObject = function(objectToClone) {
    var visited = new Set();
    var traverseObject = function(item) {
        if (_instanceof(item, Relation)) {
            var object = getMatchingObject(item);
            if (object) {
                return cloneAndMemoizeObject(object);
            }
        } else if (Array.isArray(item)) {
            return item.map(traverseObject);
        }
        return item;
    };
    var cloneObject = function(data) {
        if (visited.has(data.id)) {
            return data;
        }
        visited.add(data.id);
        var cloned = _objectSpread({
        }, data);
        var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
        try {
            for(var _iterator = Object.entries(data)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                var _value = _slicedToArray(_step.value, 2), key = _value[0], value = _value[1];
                cloned[key] = traverseObject(value);
            }
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally{
            try {
                if (!_iteratorNormalCompletion && _iterator.return != null) {
                    _iterator.return();
                }
            } finally{
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }
        return cloned;
    };
    var cloneAndMemoizeObject = R.memoizeWith(R.prop("id"), cloneObject);
    return cloneObject(objectToClone);
};
export var getRelations = function(list) {
    return list.map(deepCloneObject);
};
