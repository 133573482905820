export const isString = (value) => typeof value === "string";
export const isNumber = (value) => typeof value === "number";
export const isArrayOfStrings = (value) => Array.isArray(value) && value.every((item) => typeof item === "string");
// Validate Vivid custom events
export const isChangeEvent = (e) => {
    var _a, _b;
    return e.currentTarget !== undefined &&
        ((_a = e.detail) === null || _a === void 0 ? void 0 : _a.source) === "interaction" &&
        ((_b = e.detail) === null || _b === void 0 ? void 0 : _b.selected);
};
export const isMenuEvent = (e) => { var _a; return typeof ((_a = e.detail) === null || _a === void 0 ? void 0 : _a.index) === "number"; };
export const isNotEmpty = (value) => value !== null && value !== undefined;
