import { Instances as Instances1 } from "@neru/base/types";
import { graphql } from "msw";
import * as R from "ramda";
import { schema } from "../data";
import { DATA_TYPES } from "../types";
import { getRelations } from "../utils/data-relations/resolve-relations";
import { filterData } from "../utils/middleware/filter";
import { paginateData } from "../utils/middleware/paginate";
import { makeResponseObject } from "../utils/middleware/response-object";
import { sortData } from "../utils/middleware/sort";
var responseObject = makeResponseObject(DATA_TYPES.instance);
export var instancesHandlers = [
    graphql.query("getInstances", function(req, res, ctx) {
        var allInstances = schema[DATA_TYPES.instance];
        var _variables = req.variables, limit = _variables.limit, offset = _variables.offset, orderBy = _variables.orderBy, where = _variables.where;
        var filterRules = filterData(where);
        var sortRules = sortData(orderBy);
        var paginateRules = paginateData(offset, limit);
        var result = R.pipe(getRelations, filterRules, sortRules, paginateRules, responseObject)(allInstances);
        return res(ctx.data(result));
    }),
    graphql.query("getInstancesFormData", function(req, res, ctx) {
        var where = req.variables.where;
        var allInstances = schema[DATA_TYPES.instance];
        var filterRules = filterData(where);
        var reducer = function(param, param1) {
            var Instances = param.Instances, Region = param1.Region;
            return {
                Instances: Instances.find(function(instance) {
                    var ref;
                    return ((ref = instance.Region) === null || ref === void 0 ? void 0 : ref.id) === (Region === null || Region === void 0 ? void 0 : Region.id);
                }) ? Instances : Instances.concat([
                    {
                        Region: Region
                    }
                ])
            };
        };
        var result = R.pipe(getRelations, filterRules, R.reduce(reducer, {
            Instances: []
        }))(allInstances);
        return res(ctx.data(result));
    }),
    graphql.query("getConfig", function(req, res, ctx) {
        var allInstances = schema[DATA_TYPES.instance];
        var where = req.variables.where;
        var filterRules = filterData(where);
        var result = R.pipe(getRelations, filterRules, responseObject)(allInstances);
        return res(ctx.data(result));
    }), 
];
