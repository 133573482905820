import * as R from "ramda";
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
var _obj;
export var makeResponseObject = R.curry(function(dataType, result) {
    var _data;
    var data = (_data = result.data) !== null && _data !== void 0 ? _data : result;
    var _total;
    var total = (_total = result.total) !== null && _total !== void 0 ? _total : result.length;
    return _obj = {
    }, _defineProperty(_obj, dataType, data), _defineProperty(_obj, "".concat(dataType, "_aggregate"), {
        aggregate: {
            count: total
        }
    }), _obj;
});
export var makeFaasResponseObject = function(res) {
    return {
        error: "",
        res: res
    };
};
