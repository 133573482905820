import { graphql } from "msw";
import * as R from "ramda";
import { schema } from "../data";
import { DATA_TYPES } from "../types";
import { getRelations } from "../utils/data-relations/resolve-relations";
import { filterData } from "../utils/middleware/filter";
import { paginateData } from "../utils/middleware/paginate";
import { makeResponseObject } from "../utils/middleware/response-object";
import { sortData } from "../utils/middleware/sort";
var responseObject = makeResponseObject(DATA_TYPES.project);
export var projectsHandlers = [
    graphql.query("getProjects", function(req, res, ctx) {
        var allProjects = schema[DATA_TYPES.project];
        var _variables = req.variables, limit = _variables.limit, offset = _variables.offset, orderBy = _variables.orderBy, where = _variables.where;
        var filterRules = filterData(where);
        var sortRules = sortData(orderBy);
        var paginateRules = paginateData(offset, limit);
        var result = R.pipe(getRelations, filterRules, sortRules, paginateRules, responseObject)(allProjects);
        return res(ctx.data(result));
    }),
    graphql.query("getProject", function(req, res, ctx) {
        var allProjects = schema[DATA_TYPES.project];
        var where = req.variables.where;
        var filterRules = filterData(where);
        var result = R.pipe(getRelations, filterRules, responseObject)(allProjects);
        return res(ctx.data(result));
    }), 
];
