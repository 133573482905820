import { jsx as _jsx } from "react/jsx-runtime";
import { Component } from "react";
import { ErrorPage } from "./ErrorPage";
class ErrorCatcher extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(error) {
        return { error, hasError: true };
    }
    componentDidCatch(error, errorInfo) {
        console.log("Unhandled error", error, errorInfo); // TODO: add proper error reporting
    }
    render() {
        if (this.state.hasError) {
            return _jsx(ErrorPage, { error: this.state.error }, void 0);
        }
        return this.props.children;
    }
}
export default ErrorCatcher;
