import * as R from "ramda";
import { datatype, generateRandomISODateForIndex, lorem, system } from "../utils/random";
function _arrayWithoutHoles(arr) {
    if (Array.isArray(arr)) {
        for(var i = 0, arr2 = new Array(arr.length); i < arr.length; i++){
            arr2[i] = arr[i];
        }
        return arr2;
    }
}
function _iterableToArray(iter) {
    if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}
function _nonIterableSpread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance");
}
function _toConsumableArray(arr) {
    return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}
var generateTimestamp = generateRandomISODateForIndex();
export var generateRandomTree = function(param) {
    var prefix = param === void 0 ? "/" : param;
    var randomFiles = R.range(1, 5).map(function(index) {
        return {
            lastModified: generateTimestamp(index),
            name: "".concat(prefix).concat(system.commonFileName()),
            size: datatype.number()
        };
    });
    var randomFolders = R.range(1, 5).map(function(index) {
        return {
            lastModified: generateTimestamp(index),
            name: "".concat(prefix).concat(lorem.word(), "/"),
            size: 0
        };
    });
    return _toConsumableArray(randomFiles).concat(_toConsumableArray(randomFolders));
};
var files = [
    {
        lastModified: "2021-08-25T14:41:17Z",
        name: "foo.sh",
        size: 13770752
    },
    {
        lastModified: "2021-04-29T15:39:56Z",
        name: "bar/",
        size: 0
    },
    {
        lastModified: "2020-10-17T22:28:21Z",
        name: "bar/baz.mp3",
        size: 72611238
    },
    {
        lastModified: "2021-03-31T02:52:51Z",
        name: "bar/qux.jpg",
        size: 838237
    },
    {
        lastModified: "2020-11-02T14:05:09Z",
        name: "bar/quux/",
        size: 0
    },
    {
        lastModified: "2020-10-08T16:50:22Z",
        name: "corge/",
        size: 0
    }, 
];
var allFiles = _toConsumableArray(files).concat(_toConsumableArray(generateRandomTree()));
export default allFiles;
