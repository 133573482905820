import { graphql } from "msw";
import * as R from "ramda";
import { schema } from "../data";
import { DATA_TYPES } from "../types";
import { getRelations } from "../utils/data-relations/resolve-relations";
import { filterData } from "../utils/middleware/filter";
import { paginateData } from "../utils/middleware/paginate";
import { makeResponseObject } from "../utils/middleware/response-object";
import { sortData } from "../utils/middleware/sort";
var responseObject = makeResponseObject(DATA_TYPES.region);
var resolveRegionsQuery = function(req, res, ctx) {
    var _variables = req.variables, limit = _variables.limit, offset = _variables.offset, orderBy = _variables.orderBy, where = _variables.where;
    var allRegions = schema[DATA_TYPES.region];
    var filterRules = filterData(where);
    var sortRules = sortData(orderBy);
    var paginateRules = paginateData(offset, limit);
    var result = R.pipe(getRelations, filterRules, sortRules, paginateRules, responseObject)(allRegions);
    return res(ctx.data(result));
};
export var regionsHandlers = [
    graphql.query("getRegions", resolveRegionsQuery), 
];
