import { graphql } from "msw";
import * as R from "ramda";
import { schema } from "../data";
import { DATA_TYPES } from "../types";
import { getRelations } from "../utils/data-relations/resolve-relations";
import { filterData } from "../utils/middleware/filter";
import { paginateData } from "../utils/middleware/paginate";
import { makeResponseObject } from "../utils/middleware/response-object";
import { sortData } from "../utils/middleware/sort";
var responseObject = makeResponseObject(DATA_TYPES.event);
export var eventsHandlers = [
    graphql.query("getEvents", function(req, res, ctx) {
        var _variables = req.variables, limit = _variables.limit, offset = _variables.offset, orderBy = _variables.orderBy, where = _variables.where;
        var allEvents = schema[DATA_TYPES.event];
        var filterRules = filterData(where);
        var sortRules = sortData(orderBy);
        var paginateRules = paginateData(offset, limit);
        var result = R.pipe(getRelations, filterRules, sortRules, paginateRules, responseObject)(allEvents);
        return res(ctx.data(result));
    }),
    graphql.query("getEventsFormData", function(req, res, ctx) {
        var where = req.variables.where;
        var allEvents = schema[DATA_TYPES.event];
        var filterRules = filterData(where);
        var sortRules = sortData([
            {
                event_type: "asc"
            }
        ]);
        var reducer = function(param, param1) {
            var EventTypes = param.EventTypes, SourceTypes = param.SourceTypes, event_type = param1.event_type, source_type = param1.source_type;
            return {
                EventTypes: EventTypes.find(function(event) {
                    return event.event_type === event_type;
                }) ? EventTypes : EventTypes.concat([
                    {
                        event_type: event_type
                    }
                ]),
                SourceTypes: SourceTypes.find(function(event) {
                    return event.source_type === source_type;
                }) ? SourceTypes : SourceTypes.concat([
                    {
                        source_type: source_type
                    }
                ])
            };
        };
        var result = R.pipe(filterRules, sortRules, R.reduce(reducer, {
            EventTypes: [],
            SourceTypes: []
        }))(allEvents);
        return res(ctx.data(result));
    }), 
];
