import { subMinutes } from "date-fns";
import * as R from "ramda";
import { datatype, generateRandomISODateForIndex, random } from "../utils/random";
function _arrayWithoutHoles(arr) {
    if (Array.isArray(arr)) {
        for(var i = 0, arr2 = new Array(arr.length); i < arr.length; i++){
            arr2[i] = arr[i];
        }
        return arr2;
    }
}
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _iterableToArray(iter) {
    if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}
function _nonIterableSpread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance");
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {
        };
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function _toConsumableArray(arr) {
    return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}
var now = new Date();
var generateTimestamp = generateRandomISODateForIndex({
    dateBetween: [
        subMinutes(now, 60),
        now
    ]
});
var logsData = [
    {
        id: "log-boeing",
        instance_id: "20354d7a-e4fe-47af-8ff6-187bca92f3f9",
        log_level: "error",
        message: "Lorem ipsum",
        source_type: "provider",
        timestamp: "2010-01-16T20:19:10.566Z"
    },
    {
        context: '{"payload":"audio", "voice_name":"Amy", "level":1}',
        id: "log-lockheed",
        instance_id: "20354d7a-e4fe-47af-8ff6-187bca92f3f9",
        log_level: "info",
        message: "Sit amet",
        source_type: "conversation"
    },
    {
        context: '{"payload":"audio", "voice_name":"Amy", "level":1}',
        id: "log-cirrus",
        instance_id: "20354d7a-e4fe-47af-8ff6-187bca92f3f9",
        log_level: "warning",
        message: "Lorem ipsum sit amet",
        source_type: "video"
    },
    {
        id: "log-douglas",
        instance_id: "inst-husky",
        log_level: "warning",
        message: "Dolor sit",
        source_type: "video"
    },
    {
        id: "log-airbus",
        instance_id: "inst-shepherd",
        log_level: "warning",
        message: "Sed vel convallis sapien",
        source_type: "conversation"
    },
    {
        id: "log-piper",
        instance_id: "inst-shepherd",
        log_level: "others",
        message: "Nulla a tincidunt risus",
        source_type: "sms"
    }, 
];
var randomLogs = function(instance_id) {
    return R.range(1, 200).map(function() {
        return {
            id: datatype.uuid(),
            instance_id: instance_id,
            log_level: "".concat(random.word(), ".").concat(random.word()),
            message: random.word(),
            source_type: random.word()
        };
    });
};
var logs = _toConsumableArray(logsData).concat(_toConsumableArray(randomLogs("inst-husky"))).map(function(log, index) {
    return _objectSpread({
        timestamp: generateTimestamp(index)
    }, log);
});
export default logs;
