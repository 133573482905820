export const theme = {
    borders: {
        solid: "1px solid",
    },
    colors: {
        greys: {
            100: "#F2F2F2",
            300: "#ccc",
            500: "#666",
        },
    },
    fontSizes: {
        lg: 20,
        md: 16,
        sm: 14,
        xl: 40,
        xs: 12,
    },
    fontWeights: {
        medium: 500,
    },
    radii: {
        md: 6,
    },
    space: {
        lg: 24,
        md: 16,
        sm: 8,
        xl: 32,
        xs: 4,
    },
    transition: {
        fast: "all 200ms ease-out",
        medium: "all 400ms ease-out",
    },
};
