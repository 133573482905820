import { FILTER_TYPES } from "@neru/base/types";
import * as R from "ramda";
function _arrayWithHoles(arr) {
    if (Array.isArray(arr)) return arr;
}
function _iterableToArrayLimit(arr, i) {
    var _arr = [];
    var _n = true;
    var _d = false;
    var _e = undefined;
    try {
        for(var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _nonIterableRest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance");
}
function _slicedToArray(arr, i) {
    return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}
var isObject = function(item) {
    return R.type(item) === "Object";
};
var simpleFilter = function(item, field, kind, value) {
    switch(kind){
        case FILTER_TYPES._eq:
            return item[field] === value;
        case FILTER_TYPES._gte:
            var gteValue = item[field];
            if (typeof gteValue === "string") {
                // assume it's a date string
                return Date.parse(gteValue) >= Date.parse(value);
            }
            return false;
        case FILTER_TYPES._ilike:
            var ilikeValue = item[field];
            if (typeof ilikeValue === "string") {
                return ilikeValue.toLowerCase().includes(value.replace(/%/g, "").toLowerCase());
            }
            return false;
        case FILTER_TYPES._in:
            var inValue = item[field];
            if (Array.isArray(value)) {
                return value.includes(inValue);
            }
            return false;
        case FILTER_TYPES._like:
            var likeValue = item[field];
            if (typeof likeValue === "string") {
                return likeValue.includes(value.replace(/%/g, ""));
            }
            return false;
        case FILTER_TYPES._lt:
            var ltValue = item[field];
            if (typeof ltValue === "string") {
                // assume it's a date string
                return Date.parse(ltValue) < Date.parse(value);
            }
            return false;
        default:
            return false;
    }
};
var traverseFilterEntry = function(item, param1) {
    var _param1 = _slicedToArray(param1, 2), field = _param1[0], rule = _param1[1];
    return Object.entries(rule).reduce(function(verdict, param) {
        var _param = _slicedToArray(param, 2), kind = _param[0], subRule = _param[1];
        return verdict && simpleFilter(item, field, kind, subRule);
    }, true);
};
var traverseFilter = function(item, filterEntry, verdict) {
    var _filterEntry = _slicedToArray(filterEntry, 2), key = _filterEntry[0], entry = _filterEntry[1];
    var nestedItem = item[key];
    if (isObject(entry)) {
        var nextEntry = Object.entries(entry).pop();
        if (!nextEntry) {
            throw new Error("Filter structure with key ".concat(key, " doesn't match data structure."));
        }
        if (isObject(nestedItem)) {
            return traverseFilter(nestedItem, nextEntry, verdict);
        } else if (Array.isArray(nestedItem)) {
            return nestedItem.find(function(nestedItemEntry) {
                return traverseFilter(nestedItemEntry, nextEntry, verdict);
            });
        }
    }
    return verdict && traverseFilterEntry(item, filterEntry);
};
export var filterData = R.curry(function(filter, data) {
    return filter ? data.filter(function(item) {
        return Object.entries(filter).reduce(function(verdict, filterEntry) {
            return traverseFilter(item, filterEntry, verdict);
        }, Boolean(true));
    }) : data;
});
