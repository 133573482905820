import { addMinutes, format, subMinutes } from "date-fns";
const changeTimeByTimezoneOffset = (date) => {
    const offset = date.getTimezoneOffset();
    return Math.sign(offset) !== -1
        ? addMinutes(date, offset)
        : subMinutes(date, Math.abs(offset));
};
export const formatDateTime = (date) => {
    const dateToFormat = typeof date === "string" ? new Date(date) : date;
    return format(dateToFormat, "yyyy-MM-dd HH:mm:ss");
};
export const formatDateTimeISO = (date) => {
    const dateUTC = changeTimeByTimezoneOffset(date);
    return format(dateUTC, "yyyy-MM-dd'T'HH:mm:ss'Z'");
};
