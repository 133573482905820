import { DateTime } from "@neru/base/utils";
import { date } from "faker";
export { datatype, lorem, random, system } from "faker";
var formatDateTimeISO = DateTime.formatDateTimeISO;
/**
 * Generate random date.
 *
 * @param options Optional parameters. Can optionally contain the following properties:
 *                dateBetween - a tuple of dates, within which the random date will be generated.
 *                indexBetween - a tuple of indexes for which generated date is in dateBetween limits.
 * @param index   Index of an item to be checked against indexBetween range.
 *
 * @return        ISO date string.
 */ export var generateRandomISODateForIndex = function(param) {
    var ref = param === void 0 ? {
    } : param, dateBetween = ref.dateBetween, indexBetween = ref.indexBetween;
    return function(index) {
        if (!dateBetween) {
            return formatDateTimeISO(date.past());
        }
        if (!index || !indexBetween) {
            return formatDateTimeISO(dateBetween ? date.between(dateBetween[0], dateBetween[1]) : date.past());
        }
        if (index >= indexBetween[0] && index <= indexBetween[1]) {
            return formatDateTimeISO(date.between(dateBetween[0], dateBetween[1]));
        }
        return formatDateTimeISO(date.past());
    };
};
