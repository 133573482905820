import { configureStore } from "@reduxjs/toolkit";
import { authMiddleware, authReducer } from "./auth/auth";
import { notificationsReducer } from "./notifications/notifications";
export { setAccessToken, setAccountId } from "./auth/auth";
export { newNotification, removeNotification, } from "./notifications/notifications";
export const store = configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authMiddleware),
    reducer: {
        auth: authReducer,
        notifications: notificationsReducer,
    },
});
