import "antd/dist/antd.css";

import { ErrorCatcher, NotificationsTicker } from "@neru/base/components";
import { SplitFactory } from "@splitsoftware/splitio-react";
import VwcCircularProgress from "@vonage/vivid-react/VwcCircularProgress";
import { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import * as S from "./styles";
import { SPLIT_CONFIG } from "./utils/config";

const Projects = lazy(() => import("./features/Projects/Projects"));
const Project = lazy(() => import("./features/Projects/Project"));

export const App = () => (
  <ThemeProvider theme={S.theme}>
    <S.AdminPage>
      <ErrorCatcher>
        <SplitFactory config={SPLIT_CONFIG}>
          <Suspense
            fallback={
              <S.Center>
                <VwcCircularProgress indeterminate />
              </S.Center>
            }
          >
            <Switch>
              <Route component={Project} exact path="/:projectName" />
              <Route component={Projects} exact path="/" />
            </Switch>
          </Suspense>
        </SplitFactory>

        <NotificationsTicker />
      </ErrorCatcher>
    </S.AdminPage>
  </ThemeProvider>
);
