export const ROUTER_BASENAME = process.env.REACT_APP_ROUTER_BASENAME || "";

export const PAGE_SIZE = parseInt(process.env.REACT_APP_PAGE_SIZE || "10", 10);

export const AUTH_CONFIG = {
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  issuer: `https://${process.env.REACT_APP_AUTH_ISSUER}`,
  redirectUri:
    window.location.origin +
    ROUTER_BASENAME +
    process.env.REACT_APP_AUTH_REDIRECT_URL,
};

export const SPLIT_CONFIG = {
  core: {
    authorizationKey: process.env.REACT_APP_SPLIT_AUTHORIZATION_KEY,
    key: process.env.REACT_APP_SPLIT_KEY,
  },
};
