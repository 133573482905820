import { Page } from "@neru/base/styles";
import styled from "styled-components";

export { Center, theme } from "@neru/base/styles";

export const AdminPage = styled(Page)`
  margin: auto;
  max-width: 1340px;

  table {
    td,
    th {
      border: inherit;
      font-size: inherit;
    }
  }
`;
