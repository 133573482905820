import * as R from "ramda";
function _arrayWithHoles(arr) {
    if (Array.isArray(arr)) return arr;
}
function _iterableToArrayLimit(arr, i) {
    var _arr = [];
    var _n = true;
    var _d = false;
    var _e = undefined;
    try {
        for(var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _nonIterableRest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance");
}
function _slicedToArray(arr, i) {
    return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}
export var sortData = R.curry(function(sortParams, data) {
    var sortParam = sortParams && R.head(sortParams);
    if (!sortParam) {
        return data;
    }
    var ref = _slicedToArray(Object.entries(sortParam)[0], 2), field = ref[0], direction = ref[1];
    return data.sort(function(a, b) {
        var aValue = String(a[field]);
        var bValue = String(b[field]);
        if (aValue < bValue) {
            return direction === "asc" ? -1 : 1;
        } else if (aValue > bValue) {
            return direction === "asc" ? 1 : -1;
        }
        return 0;
    });
});
