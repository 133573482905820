var DATA_TYPES1;
export { DATA_TYPES1 as DATA_TYPES,  };
(function(DATA_TYPES) {
    DATA_TYPES["deployment"] = "Deployments";
    DATA_TYPES["event"] = "Events";
    DATA_TYPES["instance"] = "Instances";
    DATA_TYPES["log"] = "Logs";
    DATA_TYPES["project"] = "Projects";
    DATA_TYPES["region"] = "Regions";
})(DATA_TYPES1 || (DATA_TYPES1 = {
}));
