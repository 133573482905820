import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { newNotification, removeNotification, } from "../modules";
import { NotificationType } from "../types";
const useAppDispatch = () => useDispatch();
const defaultHeaders = {
    [NotificationType.SUCCESS]: "Success!",
    [NotificationType.ALERT]: "Error!",
    [NotificationType.ANNOUNCEMENT]: "Announcement",
    [NotificationType.INFO]: "",
    [NotificationType.WARNING]: "Warning!",
};
export const useNotifications = () => {
    const dispatch = useAppDispatch();
    const showNotification = useCallback((type, message, header) => {
        dispatch(newNotification({
            header: header !== null && header !== void 0 ? header : defaultHeaders[type],
            message,
            type,
        }));
    }, [dispatch]);
    const dismissNotification = useCallback((id) => dispatch(removeNotification(id)), [dispatch]);
    const showSuccess = useCallback((message, options) => showNotification(NotificationType.SUCCESS, message, options === null || options === void 0 ? void 0 : options.header), [showNotification]);
    const showError = useCallback((message, options) => showNotification(NotificationType.ALERT, message, options === null || options === void 0 ? void 0 : options.header), [showNotification]);
    return { dismissNotification, showError, showSuccess };
};
