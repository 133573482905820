function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError("Cannot call a class as a function");
    }
}
export var Relation = function Relation(__typename, id) {
    "use strict";
    _classCallCheck(this, Relation);
    this.__typename = __typename;
    this.id = id;
};
export var getRelation = function(__typename, id) {
    return new Relation(__typename, id);
};
