import { createSlice } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";
import { NotificationType } from "../../types";
const initialState = {
    notifications: [],
};
const notificationsSlice = createSlice({
    initialState,
    name: "notifications",
    reducers: {
        newNotification: (state, { payload }) => {
            var _a;
            state.notifications.push({
                header: (_a = payload.header) !== null && _a !== void 0 ? _a : "",
                id: `${payload.type}-${nanoid()}`,
                message: payload.message,
                type: payload.type,
            });
        },
        removeNotification: (state, { payload }) => {
            state.notifications = state.notifications.filter(({ id }) => id !== payload);
        },
    },
});
export const { newNotification, removeNotification } = notificationsSlice.actions;
export const notificationsReducer = notificationsSlice.reducer;
