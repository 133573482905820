import { subMinutes } from "date-fns";
import * as R from "ramda";
import { datatype, generateRandomISODateForIndex, random } from "../utils/random";
function _arrayWithoutHoles(arr) {
    if (Array.isArray(arr)) {
        for(var i = 0, arr2 = new Array(arr.length); i < arr.length; i++){
            arr2[i] = arr[i];
        }
        return arr2;
    }
}
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _iterableToArray(iter) {
    if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}
function _nonIterableSpread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance");
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {
        };
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function _toConsumableArray(arr) {
    return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}
var now = new Date();
var generateTimestamp = generateRandomISODateForIndex({
    dateBetween: [
        subMinutes(now, 60),
        now
    ],
    indexBetween: [
        10,
        120
    ]
});
var eventsData = [
    {
        details: '{"from":{"type":"sms","number":"55544"},"to":{"type":"sms","number":"22456"},"content":{"type":"text","text":"What is your location ID?"}}',
        event_type: "provider.action.received",
        id: "even-audi",
        instance_id: "20354d7a-e4fe-47af-8ff6-187bca92f3f9",
        source_type: "provider"
    },
    {
        event_type: "callback.fired",
        id: "even-ford",
        instance_id: "20354d7a-e4fe-47af-8ff6-187bca92f3f9",
        source_type: "callback"
    },
    {
        event_type: "callback.fired",
        id: "even-honda",
        instance_id: "inst-shepherd",
        source_type: "callback"
    },
    {
        event_type: "callback.subscribed",
        id: "even-nissan",
        instance_id: "inst-husky",
        source_type: "provider"
    },
    {
        event_type: "provider.action.received",
        id: "even-toyota",
        instance_id: "inst-shepherd",
        source_type: "provider"
    },
    {
        event_type: "callback.subscribed",
        id: "even-mazda",
        instance_id: "inst-collie",
        source_type: "provider"
    }, 
];
var randomEvents = function(instance_id) {
    return R.range(1, 200).map(function() {
        return {
            event_type: "".concat(random.word(), ".").concat(random.word()),
            id: datatype.uuid(),
            instance_id: instance_id,
            source_type: random.word()
        };
    });
};
var events = _toConsumableArray(eventsData).concat(_toConsumableArray(randomEvents("inst-husky"))).map(function(event, index) {
    return _objectSpread({
        timestamp: generateTimestamp(index)
    }, event);
});
export default events;
