import { rest } from "msw";
import files, { generateRandomTree } from "../data/files";
import { makeFaasResponseObject } from "../utils/middleware/response-object";
var filterByPrefix = function(value, objects) {
    return objects.filter(function(param) {
        var name = param.name;
        return name !== value && name.startsWith(value);
    });
};
var filterRootDirObjects = function(objects) {
    return objects.filter(function(param) {
        var name = param.name;
        var index = name.indexOf("/");
        return index === name.length - 1 || index === -1;
    });
};
export var filesHandlers = [
    rest.post("".concat(process.env.REACT_APP_OPENFAAS_URL, "/function/vonage-assets"), function(req, res, ctx) {
        var prefix = req.body.prefix;
        var directoryFiles = prefix ? filterByPrefix(prefix, files) : filterRootDirObjects(files);
        // Generate random structure with 80% probability if no files predefined
        // for path
        if (prefix && !directoryFiles.length) {
            directoryFiles = Math.random() <= 0.8 ? generateRandomTree(prefix) : directoryFiles;
        }
        return res(ctx.json(makeFaasResponseObject(directoryFiles)));
    }), 
];
