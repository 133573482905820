import { assetHandlers } from "./assets";
import { deploymentsHandlers } from "./deployments";
import { eventsHandlers } from "./events";
import { filesHandlers } from "./files";
import { instancesHandlers } from "./instances";
import { logsHandlers } from "./logs";
import { projectsHandlers } from "./projects";
import { regionsHandlers } from "./regions";
function _arrayWithoutHoles(arr) {
    if (Array.isArray(arr)) {
        for(var i = 0, arr2 = new Array(arr.length); i < arr.length; i++){
            arr2[i] = arr[i];
        }
        return arr2;
    }
}
function _iterableToArray(iter) {
    if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}
function _nonIterableSpread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance");
}
function _toConsumableArray(arr) {
    return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}
export var handlers = _toConsumableArray(assetHandlers).concat(_toConsumableArray(deploymentsHandlers), _toConsumableArray(eventsHandlers), _toConsumableArray(filesHandlers), _toConsumableArray(instancesHandlers), _toConsumableArray(logsHandlers), _toConsumableArray(projectsHandlers), _toConsumableArray(regionsHandlers));
