import { graphql } from "msw";
import * as R from "ramda";
import { schema } from "../data";
import { DATA_TYPES } from "../types";
import { getRelations } from "../utils/data-relations/resolve-relations";
import { filterData } from "../utils/middleware/filter";
import { paginateData } from "../utils/middleware/paginate";
import { makeResponseObject } from "../utils/middleware/response-object";
import { sortData } from "../utils/middleware/sort";
var responseObject = makeResponseObject(DATA_TYPES.log);
export var logsHandlers = [
    graphql.query("getLogs", function(req, res, ctx) {
        var allLogs = schema[DATA_TYPES.log];
        var _variables = req.variables, limit = _variables.limit, offset = _variables.offset, orderBy = _variables.orderBy, where = _variables.where;
        var filterRules = filterData(where);
        var sortRules = sortData(orderBy);
        var paginateRules = paginateData(offset, limit);
        var result = R.pipe(getRelations, filterRules, sortRules, paginateRules, responseObject)(allLogs);
        return res(ctx.data(result));
    }),
    graphql.query("getLogsFormData", function(req, res, ctx) {
        var where = req.variables.where;
        var allLogs = schema[DATA_TYPES.log];
        var filterRules = filterData(where);
        var sortRules = sortData([
            {
                log_level: "asc"
            }
        ]);
        var reducer = function(param, param1) {
            var LogLevels = param.LogLevels, SourceTypes = param.SourceTypes, log_level = param1.log_level, source_type = param1.source_type;
            return {
                LogLevels: LogLevels.find(function(log) {
                    return log.log_level === log_level;
                }) ? LogLevels : LogLevels.concat([
                    {
                        log_level: log_level
                    }
                ]),
                SourceTypes: SourceTypes.find(function(log) {
                    return log.source_type === source_type;
                }) ? SourceTypes : SourceTypes.concat([
                    {
                        source_type: source_type
                    }
                ])
            };
        };
        var result = R.pipe(filterRules, sortRules, R.reduce(reducer, {
            LogLevels: [],
            SourceTypes: []
        }))(allLogs);
        return res(ctx.data(result));
    }), 
];
