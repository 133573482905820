import { subDays } from "date-fns";
import { DATA_TYPES } from "../types";
import { getRelation } from "../utils/data-relations/relation";
import { generateRandomISODateForIndex } from "../utils/random";
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {
        };
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
var now = new Date();
var generateTimestamp = generateRandomISODateForIndex({
    dateBetween: [
        subDays(now, 30),
        now
    ]
});
var instancesData = [
    {
        ActiveDeployment: getRelation(DATA_TYPES.deployment, "depl-pizza"),
        Project: getRelation(DATA_TYPES.project, "proj-corfu"),
        Region: getRelation(DATA_TYPES.region, "reg-europe"),
        created_at: "2021-11-16T12:00:00",
        id: "20354d7a-e4fe-47af-8ff6-187bca92f3f9",
        name: "Poodle",
        project_id: "proj-corfu",
        region_id: "reg-europe"
    },
    {
        ActiveDeployment: getRelation(DATA_TYPES.deployment, "depl-minestrone"),
        Project: getRelation(DATA_TYPES.project, "proj-kos"),
        Region: getRelation(DATA_TYPES.region, "reg-us"),
        created_at: "2021-11-10T10:00:00",
        id: "inst-shepherd",
        name: "Shepherd",
        project_id: "proj-kos",
        region_id: "reg-us"
    },
    {
        ActiveDeployment: getRelation(DATA_TYPES.deployment, "depl-risotto"),
        Project: getRelation(DATA_TYPES.project, "proj-corfu"),
        Region: getRelation(DATA_TYPES.region, "reg-europe"),
        id: "inst-husky",
        name: "Husky",
        project_id: "proj-corfu",
        region_id: "reg-europe"
    },
    {
        Project: getRelation(DATA_TYPES.project, "proj-kos"),
        Region: getRelation(DATA_TYPES.region, "reg-europe"),
        id: "inst-dobermann",
        name: "Dobermann",
        project_id: "proj-kos",
        region_id: "reg-europe"
    },
    {
        Project: getRelation(DATA_TYPES.project, "proj-kos"),
        Region: getRelation(DATA_TYPES.region, "reg-asia"),
        id: "inst-retriever",
        name: "Retriever",
        project_id: "proj-kos",
        region_id: "reg-asia"
    },
    {
        Project: getRelation(DATA_TYPES.project, "proj-rhodes"),
        Region: getRelation(DATA_TYPES.region, "reg-us"),
        id: "inst-rottweiler",
        name: "Rottweiler",
        project_id: "proj-rhodes",
        region_id: "reg-us"
    },
    {
        ActiveDeployment: getRelation(DATA_TYPES.deployment, "depl-cappuccino"),
        Project: getRelation(DATA_TYPES.project, "proj-corfu"),
        Region: getRelation(DATA_TYPES.region, "reg-europe"),
        id: "inst-greyhound",
        name: "Greyhound",
        project_id: "proj-corfu",
        region_id: "reg-europe"
    },
    {
        Project: getRelation(DATA_TYPES.project, "proj-rhodes"),
        Region: getRelation(DATA_TYPES.region, "reg-us"),
        id: "inst-bulldog",
        name: "Bulldog",
        project_id: "proj-rhodes",
        region_id: "reg-us"
    },
    {
        Project: getRelation(DATA_TYPES.project, "proj-crete"),
        Region: getRelation(DATA_TYPES.region, "reg-asia"),
        id: "inst-collie",
        name: "Collie",
        project_id: "proj-crete",
        region_id: "reg-asia"
    }, 
];
var instances = instancesData.map(function(instance) {
    return _objectSpread({
        created_at: generateTimestamp(),
        service_name: "neru-".concat(instance.name.toLocaleLowerCase(), "-service")
    }, instance);
});
export default instances;
