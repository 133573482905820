import "./index.css";

import { store } from "@neru/base/modules";
import { initVivid } from "@vonage/vivid-react";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import { App } from "./App";
import { AppWithAuth } from "./AppWithAuth";
import reportWebVitals from "./reportWebVitals";
import { ROUTER_BASENAME } from "./utils/config";

if (process.env.REACT_APP_MOCK) {
  require("@neru/mock/worker");
}

const rootElement = document.getElementById("root");

const renderApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Router basename={ROUTER_BASENAME}>
          {process.env.NODE_ENV === "production" ? <AppWithAuth /> : <App />}
        </Router>
      </Provider>
    </React.StrictMode>,
    rootElement
  );
};

initVivid(rootElement, renderApp);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
