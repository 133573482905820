import { useAuth } from "@neru/base/hooks";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security, useOktaAuth } from "@okta/okta-react";
import { LoginCallback } from "@okta/okta-react";
import VwcCircularProgress from "@vonage/vivid-react/VwcCircularProgress";
import { useHistory, useLocation } from "react-router-dom";
import { Route, Switch } from "react-router-dom";

import { App } from "./App";
import * as S from "./styles";
import { AUTH_CONFIG, ROUTER_BASENAME } from "./utils/config";

const oktaAuth = new OktaAuth(AUTH_CONFIG);

const AppWithToken = () => {
  const location = useLocation();
  const { authState } = useOktaAuth();
  const { saveAccessToken } = useAuth();

  if (location.pathname !== process.env.REACT_APP_AUTH_REDIRECT_URL) {
    if (authState) {
      if (!authState.isAuthenticated) {
        oktaAuth.signInWithRedirect();
      } else {
        saveAccessToken(authState.accessToken?.accessToken);
      }
    }

    if (!authState?.isAuthenticated) {
      return (
        <S.Center>
          <VwcCircularProgress indeterminate />
        </S.Center>
      );
    }
  }

  return (
    <>
      <Switch>
        <Route
          component={LoginCallback}
          path={process.env.REACT_APP_AUTH_REDIRECT_URL}
        />
      </Switch>
      <App />
    </>
  );
};

export const AppWithAuth = () => {
  const history = useHistory();
  const restoreOriginalUri = async (_: unknown, originalUri?: string) => {
    history.replace(
      toRelativeUrl(
        (originalUri || "/").replace(ROUTER_BASENAME, ""),
        window.location.origin
      )
    );
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <AppWithToken />
    </Security>
  );
};
