import { DATA_TYPES } from "../types";
import deployments from "./deployments";
import events from "./events";
import instances from "./instances";
import logs from "./logs";
import projects from "./projects";
import regions from "./regions";
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
var _obj;
export var schema = (_obj = {
}, _defineProperty(_obj, DATA_TYPES.deployment, deployments), _defineProperty(_obj, DATA_TYPES.event, events), _defineProperty(_obj, DATA_TYPES.instance, instances), _defineProperty(_obj, DATA_TYPES.log, logs), _defineProperty(_obj, DATA_TYPES.project, projects), _defineProperty(_obj, DATA_TYPES.region, regions), _obj);
