import { createSlice, } from "@reduxjs/toolkit";
const initialState = {
    accessToken: undefined,
    accountId: undefined,
};
export const authMiddleware = (_) => (next) => (action) => {
    if (authSlice.actions.setAccountId.match(action)) {
        if (action.payload) {
            sessionStorage.setItem("accountId", action.payload);
        }
        else {
            sessionStorage.removeItem("accountId");
        }
    }
    return next(action);
};
const authSlice = createSlice({
    initialState,
    name: "auth",
    reducers: {
        setAccessToken: (state, { payload }) => {
            state.accessToken = payload;
        },
        setAccountId: (state, { payload }) => {
            state.accountId = payload;
        },
    },
});
export const { setAccessToken, setAccountId } = authSlice.actions;
export const authReducer = authSlice.reducer;
