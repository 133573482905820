import css from "@styled-system/css";
import styled from "styled-components";
import { borders, color, flexbox, layout, position, space, typography, } from "styled-system";
import { theme } from "./theme";
const calculateSpacerPadding = (props) => {
    var _a, _b;
    const size = (_a = props.size) !== null && _a !== void 0 ? _a : "md";
    const padding = ((_b = props.theme) === null || _b === void 0 ? void 0 : _b.space) ? props.theme.space[size] / 2 : 0;
    return props.vertical ? `${padding}px 0` : `0 ${padding}px`;
};
export const Box = styled.div(layout, position, space);
export const Card = styled.article(css({
    "&:hover": {
        borderColor: "greys.500",
    },
    border: "solid",
    borderColor: "greys.300",
    borderRadius: "md",
    cursor: "pointer",
    padding: "lg",
}), space);
export const Center = styled.div(css({
    alignContent: "center",
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
}));
export const Column = styled.div(css({
    display: "flex",
    flexBasis: 0,
    flexDirection: "column",
}), borders, flexbox, layout);
export const ElevatedCard = styled.article(css({
    borderRadius: "md",
    boxShadow: "0px 4px 2px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 2px 8px rgba(0, 0, 0, 0.1)",
    padding: "lg",
}), space);
export const Grid = styled.div(css({
    display: "grid",
    gridGap: ["xl"],
    gridTemplateColumns: ({ columns = 2 }) => `repeat(${columns}, 1fr)`,
}));
export const Page = styled.div(css({
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
    padding: "xl",
}), layout, space);
export const Row = styled.div(css({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
}), flexbox, layout);
export const Scrollable = styled.div(css({
    height: "100%",
    overflowY: "scroll",
}));
export const Spacer = styled.div `
  display: ${({ vertical }) => vertical ? "block" : "inline-block"};
  padding: ${calculateSpacerPadding};
`;
export const Text = styled.p(css({
    fontSize: "md",
}), color, space, typography);
