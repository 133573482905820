import { DATA_TYPES } from "../types";
import { getRelation } from "../utils/data-relations/relation";
function _arrayWithoutHoles(arr) {
    if (Array.isArray(arr)) {
        for(var i = 0, arr2 = new Array(arr.length); i < arr.length; i++){
            arr2[i] = arr[i];
        }
        return arr2;
    }
}
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _iterableToArray(iter) {
    if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}
function _nonIterableSpread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance");
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {
        };
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function _toConsumableArray(arr) {
    return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}
var projectsData = [
    {
        Instances: [
            getRelation(DATA_TYPES.instance, "20354d7a-e4fe-47af-8ff6-187bca92f3f9"),
            getRelation(DATA_TYPES.instance, "inst-husky"),
            getRelation(DATA_TYPES.instance, "inst-greyhound"), 
        ],
        api_account_id: "acc-humpback",
        created_at: "2021-07-09T17:03:58.633388",
        id: "proj-corfu",
        name: "Corfu"
    },
    {
        Instances: [
            getRelation(DATA_TYPES.instance, "inst-shepherd"),
            getRelation(DATA_TYPES.instance, "inst-dobermann"),
            getRelation(DATA_TYPES.instance, "inst-retriever"), 
        ],
        api_account_id: "acc-beluga",
        created_at: "2021-07-08T12:11:12.298518",
        id: "proj-kos",
        name: "Kos"
    },
    {
        Instances: [
            getRelation(DATA_TYPES.instance, "inst-rottweiler"),
            getRelation(DATA_TYPES.instance, "inst-bulldog"), 
        ],
        api_account_id: "acc-narwhal",
        created_at: "2021-07-07T02:12:01.573821",
        id: "proj-rhodes",
        name: "Rhodes"
    },
    {
        Instances: [
            getRelation(DATA_TYPES.instance, "inst-collie")
        ],
        api_account_id: "acc-humpback",
        created_at: "2021-05-11T08:00:44.162173",
        id: "proj-crete",
        name: "Crete"
    },
    {
        Instances: [],
        api_account_id: "acc-narwhal",
        created_at: "2021-03-23T07:48:16.379508",
        id: "proj-ithaca",
        name: "Ithaca"
    },
    {
        Instances: [],
        api_account_id: "acc-humpback",
        created_at: "2021-03-06T14:37:22.080984",
        id: "proj-thassos",
        name: "Thassos"
    },
    {
        Instances: [],
        api_account_id: "acc-beluga",
        created_at: "2021-02-14T05:25:48.621878",
        id: "proj-lefkada",
        name: "Lefkada"
    },
    {
        Instances: [],
        api_account_id: "acc-beluga",
        created_at: "2021-01-22T00:00:47.350126",
        id: "proj-kefalonia",
        name: "Kefalonia"
    }, 
];
var projects = _toConsumableArray(projectsData).map(function(project) {
    return _objectSpread({
    }, project, {
        Instances_aggregate: {
            aggregate: {
                count: project.Instances.length
            }
        }
    });
});
export default projects;
