import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setAccessToken, setAccountId, } from "../modules";
const useAppSelector = useSelector;
const useAppDispatch = () => useDispatch();
export const useAuth = () => {
    const dispatch = useAppDispatch();
    const { accessToken, accountId } = useAppSelector(({ auth }) => auth);
    const saveAccessToken = useCallback((token) => dispatch(setAccessToken(token)), [dispatch]);
    const saveAccountId = useCallback((accountId) => dispatch(setAccountId(accountId)), [dispatch]);
    const logout = useCallback(() => {
        dispatch(setAccessToken());
        dispatch(setAccountId());
    }, [dispatch]);
    const authorizationHeaders = useMemo(() => {
        let headers;
        if (accessToken) {
            headers = { authorization: `Bearer ${accessToken}` };
        }
        if (accountId) {
            headers = Object.assign(Object.assign({}, (headers !== null && headers !== void 0 ? headers : {})), { accountId });
        }
        return headers;
    }, [accessToken, accountId]);
    return { authorizationHeaders, logout, saveAccessToken, saveAccountId };
};
