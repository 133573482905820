import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import VwcSnackbar from "@vonage/vivid-react/VwcSnackbar";
import { useSelector } from "react-redux";
import { useNotifications } from "../../hooks";
import { NotificationType } from "../../types";
const useAppSelector = useSelector;
const icons = {
    [NotificationType.SUCCESS]: "check-circle-solid",
    [NotificationType.ALERT]: "error-solid",
    [NotificationType.ANNOUNCEMENT]: "message-solid",
    [NotificationType.INFO]: "info-solid",
    [NotificationType.WARNING]: "warning-solid",
};
export const NotificationsTicker = () => {
    const { dismissNotification } = useNotifications();
    const { notifications } = useAppSelector(({ notifications: collection }) => collection);
    return (_jsx(_Fragment, { children: notifications.map(({ header, id, message, type }) => (_jsx(VwcSnackbar, { connotation: type, "data-testid": `snackbar-${type}`, dismissible: true, header: header, icon: icons[type], message: message, onClosing: () => dismissNotification(id), open: Boolean(id), position: "BOTTOM-END", timeoutMs: 10000 }, id))) }, void 0));
};
