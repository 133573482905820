import { DATA_TYPES } from "../types";
import { getRelation } from "../utils/data-relations/relation";
var deployments = [
    {
        Instance: getRelation(DATA_TYPES.instance, "20354d7a-e4fe-47af-8ff6-187bca92f3f9"),
        configuration: {
            key1: "value1"
        },
        created_at: "2021-11-12T14:31:11.401162",
        id: "depl-pizza",
        instance_id: "20354d7a-e4fe-47af-8ff6-187bca92f3f9"
    },
    {
        Instance: getRelation(DATA_TYPES.instance, "inst-shepherd"),
        created_at: "2021-06-15T09:30:11.401162",
        id: "depl-minestrone",
        instance_id: "inst-shepherd"
    },
    {
        Instance: getRelation(DATA_TYPES.instance, "20354d7a-e4fe-47af-8ff6-187bca92f3f9"),
        created_at: "2021-11-16T11:22:11.401162",
        id: "depl-spaghetti",
        instance_id: "20354d7a-e4fe-47af-8ff6-187bca92f3f9"
    },
    {
        Instance: getRelation(DATA_TYPES.instance, "inst-shepherd"),
        created_at: "2021-06-15T09:30:11.401162",
        id: "depl-lasagna",
        instance_id: "inst-shepherd"
    },
    {
        Instance: getRelation(DATA_TYPES.instance, "inst-shepherd"),
        created_at: "2021-06-15T09:30:11.401162",
        id: "depl-ravioli",
        instance_id: "inst-shepherd"
    },
    {
        Instance: getRelation(DATA_TYPES.instance, "inst-husky"),
        created_at: "2021-11-10T19:13:22.221162",
        id: "depl-risotto",
        instance_id: "inst-husky"
    },
    {
        Instance: getRelation(DATA_TYPES.instance, "20354d7a-e4fe-47af-8ff6-187bca92f3f9"),
        created_at: "2021-06-15T09:30:11.401162",
        id: "depl-tortellini",
        instance_id: "20354d7a-e4fe-47af-8ff6-187bca92f3f9"
    },
    {
        Instance: getRelation(DATA_TYPES.instance, "inst-husky"),
        created_at: "2021-06-15T09:30:11.401162",
        id: "depl-tiramisu",
        instance_id: "inst-husky"
    },
    {
        Instance: getRelation(DATA_TYPES.instance, "inst-greyhound"),
        configuration: {
            with: "Milk"
        },
        created_at: "2020-11-12T09:30:11.401162",
        id: "depl-cappuccino",
        instance_id: "inst-greyhound"
    }, 
];
export default deployments;
