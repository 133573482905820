var regions = [
    {
        endpoint_url_scheme: "https://client-gateway.euw.vonage.cloud",
        id: "reg-europe",
        metadata_url: "https://client-gateway.euw.vonage.cloud/v1/graphql",
        name: "Europe (London)"
    },
    {
        endpoint_url_scheme: "https://client-gateway.asia.vonage.cloud",
        id: "reg-asia",
        name: "Asia Pacific (Hong Kong)"
    },
    {
        endpoint_url_scheme: "https://client-gateway.us.vonagenetworks.net",
        id: "reg-us",
        metadata_url: "https://client-gateway.us.vonage.cloud/v1/graphql",
        name: "US East (Ohio)"
    }, 
];
export default regions;
